import React, { lazy, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

// container components
import Main from './containers/Main';
import PrivateRoute from './containers/PrivateRoute';

// feature components
import Callback from './features/login/Callback';
import SelectCompanies from './features/select-companies/SelectCompanies';
import {
  stopSessionTimer,
  removeSessionKeys,
  startSessionTimer,
  validateUserSession,
} from './features/session/session-handler';
import ErrorRedirector from './features/error-handler/ErrorRedirector';

// selectors
import {
  selectIsLoggedIn,
  revokeUserSession,
  selectSessionExpiryTime,
  selectSessionExpiryTimeInterval,
} from './features/login/token-slice';
import { setSelectedCompany } from './features/user/user-slice';

// constants
import CONSTANTS from './constants';

// Lazy load the containers for code-splitting
const GettingStartedOverview = lazy(() => import('./containers/GettingStartedOverview'));
const ContactUs = lazy(() => import('./containers/ContactUs'));
const SampleCodes = lazy(() => import('./features/sample-codes'));
const Blog = lazy(() => import('./containers/Blog'));
const CompanyList = lazy(() => import('./containers/CompanyList'));
const CompanyApps = lazy(() => import('./containers/CompanyApps'));
const LandingPage = lazy(() => import('./containers/LandingPage'));
const SwaggerPage = lazy(() => import('./containers/SwaggerPage'));
const ApiProductDetail = lazy(() => import('./containers/ApiProductDetail'));
const CreateCompanyApp = lazy(() => import('./containers/CreateCompanyApp'));
const EditCompanyApp = lazy(() => import('./containers/EditCompanyApp'));
const NotFoundPage = lazy(() => import('./containers/NotFoundPage'));
const ServerErrorPage = lazy(() => import('./containers/ServerErrorPage'));
const ApiDocumentation = lazy(() => import('./containers/ApiDocumentation'));
const InviteDevelopers = lazy(() => import('./containers/InviteDevelopers'));
const CompanyDevelopers = lazy(() => import('./containers/CompanyDevelopers'));
const PartnerOverviewPage = lazy(() => import('@pws-dev-portal/components/src/PartnerOverviewPage'));
const SitemapPage = lazy(() => import('./containers/SitemapPage'));
const AcceptTermsAndConditions = lazy(() => import('./containers/AcceptTermsAndConditions'));
const UnauthorizedPage = lazy(() => import('@pws-dev-portal/components/src/UnauthorizedPage'));
const AutoLogin = lazy(() => import('./containers/AutoLogin'));
const CustomerSuccessVideo = lazy(() => import('@pws-dev-portal/components/src/CustomerSuccessVideo'));
const SettingsPage = lazy(() => import('./containers/Settings'));
const QuotingVideo = lazy(() => import('./containers/QuotingVideo'));
const ApiProductRedirectionPage = lazy(() => import('./containers/ApiProductRedirectionPage'));
const Roadmap = lazy(() => import('./features/roadmap'));

const App = () => {
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const sessionExpiryTime = useSelector(selectSessionExpiryTime);
  const sessionExpiryTimeInterval = useSelector(selectSessionExpiryTimeInterval);

  useEffect(() => {
    // TODO: refactor for better session handling
    // create SessionComponent to handle sessions
    const onHandleLoginUser = () => {
      history.push({
        pathname: '/login',
        state: { referrer: location.pathname !== '/login' ? location.pathname : '/' },
      });
    };
    const onHandleExpireUserSession = () => {
      removeSessionKeys();
    };
    // validates user session from session handler
    validateUserSession({
      loginUser: onHandleLoginUser,
      updateUserSession: onHandleExpireUserSession,
    });
    return function cleanup() {
      // stop running session timer before closing the app
      stopSessionTimer();
    };
  }, []);

  useEffect(() => {
    // TODO: refactor for better session handling
    // create SessionComponent to handle sessions
    if (isLoggedIn) {
      const onHandleRevokeUserSession = () => {
        history.push({
          pathname: '/',
          state: { isSessionExpired: true },
        });
        dispatch(revokeUserSession());
        dispatch(setSelectedCompany(null));
      };
      // start session timer
      startSessionTimer({
        sessionExpiryTime,
        expiryTimeInterval: sessionExpiryTimeInterval,
        revokeUserSession: onHandleRevokeUserSession,
      });
    }
  }, [isLoggedIn]);

  return (
    <div className="App">
      <ErrorRedirector />
      <Switch>
        <Route path="/callback">
          <Main>
            <Callback />
          </Main>
        </Route>
        <Route path="/select-companies">
          <Main>
            <SelectCompanies />
          </Main>
        </Route>
        <Route exact path="/business-programs/:slug">
          <Main>
            <Blog type={CONSTANTS.BLOG_TYPES.ARTICLES} />
          </Main>
        </Route>
        <Route exact path="/business-programs">
          <Main>
            <Blog type={CONSTANTS.BLOG_TYPES.BUSINESS_PROGRAM} />
          </Main>
        </Route>
        <Route exact path="/blogs/:slug">
          <Main>
            <Blog type={CONSTANTS.BLOG_TYPES.ARTICLES} />
          </Main>
        </Route>
        <Route exact path="/blogs">
          <Main>
            <Blog />
          </Main>
        </Route>
        <Route exact path="/sample-codes">
          <Main>
            <SampleCodes />
          </Main>
        </Route>
        <Route exact path="/sample-codes/:section/:language">
          <Main>
            <SampleCodes />
          </Main>
        </Route>
        <Route exact path="/getting-started/overview">
          <Main>
            <GettingStartedOverview />
          </Main>
        </Route>
        <Route exact path="/partner-overview">
          <Main>
            <PartnerOverviewPage />
          </Main>
        </Route>
        <Route exact path="/customer-success-overview">
          <Main>
            <CustomerSuccessVideo />
          </Main>
        </Route>
        <Route exact path="/quoting-overview">
          <Main>
            <QuotingVideo />
          </Main>
        </Route>
        <Route exact path="/:env/companies">
          <PrivateRoute>
            <Main>
              <CompanyList />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/:env/companies/:name/apps">
          <PrivateRoute>
            <Main>
              <CompanyApps />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/:env/companies/:name/apps/create">
          <PrivateRoute>
            <Main>
              <CreateCompanyApp />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/:env/companies/:name/apps/:appName">
          <PrivateRoute>
            <Main>
              <EditCompanyApp />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/:env/companies/:name/developers">
          <PrivateRoute>
            <Main>
              <CompanyDevelopers />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/:env/companies/:name/developers/invite">
          <PrivateRoute>
            <Main>
              <InviteDevelopers />
            </Main>
          </PrivateRoute>
        </Route>
        <Route path="/api-products/sales/:name/:apiName">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.SALES}
            />
          </Main>
        </Route>
        <Route path="/api-products/customer-success/:name/:apiName">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.CUSTOMER_SUCCESS}
            />
          </Main>
        </Route>
        <Route path="/api-products/ordering/:name/:apiName">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.ORDERING}
            />
          </Main>
        </Route>
        <Route path="/api-products/quoting/:name/:apiName">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.QUOTING}
            />
          </Main>
        </Route>
        <Route path="/api-products/sales/:name">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.SALES}
            />
          </Main>
        </Route>
        <Route path="/api-products/customer-success/:name">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.CUSTOMER_SUCCESS}
            />
          </Main>
        </Route>
        <Route path="/api-products/ordering/:name">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.ORDERING}
            />
          </Main>
        </Route>
        <Route path="/api-products/quoting/:name">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.QUOTING}
            />
          </Main>
        </Route>
        <Route path="/api-products/:category">
          <Main>
            <ApiProductRedirectionPage />
          </Main>
        </Route>
        <Route path="/customer-success/:name">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.CUSTOMER_SUCCESS}
            />
          </Main>
        </Route>
        <Route path="/customer-success">
          <Main>
            <ApiProductRedirectionPage />
          </Main>
        </Route>
        <Route path="/ordering/:name">
          <Main>
            <ApiProductDetail
              category={CONSTANTS.API_PRODUCT_CATEGORY.ORDERING}
            />
          </Main>
        </Route>
        <Route path="/ordering">
          <Main>
            <ApiProductRedirectionPage />
          </Main>
        </Route>
        <Route exact path="/contact-us">
          <Main>
            <ContactUs />
          </Main>
        </Route>
        <Route exact path="/sitemap">
          <Main>
            <SitemapPage />
          </Main>
        </Route>
        <Route exact path="/page-not-found">
          <Main>
            <NotFoundPage />
          </Main>
        </Route>
        <Route exact path="/server-error">
          <Main>
            <ServerErrorPage />
          </Main>
        </Route>
        <Route exact path="/:env/companies/:name/test-api/:doc">
          <PrivateRoute>
            <Main>
              <SwaggerPage />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/:env/companies/:name/test-api">
          <PrivateRoute>
            <Main>
              <ApiDocumentation />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/accept-terms-and-conditions">
          <Main>
            <AcceptTermsAndConditions />
          </Main>
        </Route>
        <Route path="/login">
          <Main autoLogin={true}>
            <AutoLogin />
          </Main>
        </Route>
        <Route path="/unauthorized">
          <Main>
            <UnauthorizedPage />
          </Main>
        </Route>
        <Route path="/access-denied">
          <Main>
            <LandingPage
              accessDenied={true}
            />
          </Main>
        </Route>
        <Route exact path="/logout">
          <Main>
            <LandingPage />
          </Main>
        </Route>
        <Route exact path="/:env/companies/:name/settings">
          <PrivateRoute>
            <Main>
              <SettingsPage />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/:env/companies/:name/settings/webhooks/new">
          <PrivateRoute>
            <Main>
              <SettingsPage
                addWebhook={true}
              />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/:env/companies/:name/settings/webhooks/:webhookId/edit">
          <PrivateRoute>
            <Main>
              <SettingsPage
                editWebhook={true}
              />
            </Main>
          </PrivateRoute>
        </Route>
        <Route exact path="/roadmap">
          <Main>
            <Roadmap />
          </Main>
        </Route>
        <Route exact path="/">
          <Main>
            <LandingPage />
          </Main>
        </Route>
        {/* Fallback Route if page not found */}
        <Route>
          <Main>
            <NotFoundPage />
          </Main>
        </Route>
      </Switch>
    </div>
  );
};

export default App;
