export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const USER_ROLE = {
  ADMIN: 'admin',
  NON_ADMIN: 'non-admin',
};
export const API_PRODUCT_CATEGORY = Object.freeze({
  SALES: 'sales',
  QUOTING: 'quoting',
  ORDERING: 'ordering',
  CUSTOMER_SUCCESS: 'customer-success',
});
export const CARD_VIEW_TYPE = Object.freeze({
  CARD: 'card',
  CHECKBOX: 'checkbox',
});
