import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// icons
import SendIcon from '@material-ui/icons/Send';
import CreateIcon from '@material-ui/icons/Create';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';

import { formattedMessage } from '../utils/formattedMessage';
import messages from './messages';

const ToggleUserOptions = (props) => {
  const {
    myAppsUrl,
    userLogin,
    userIsAdmin,
    userLastName,
    userFirstName,
    inviteDevelopersUrl,
    settingsUrl,
    selectedCompany,
    displayWebhookSettings,
  } = props;
  const [show, setShow] = useState(false);
  const userNavRef = useRef();

  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (userNavRef.current && !userNavRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  // only called during mounting and unmounting because of '[]'
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const settingsLink = () => (
    <div>
      <Link
        to={settingsUrl || ''}
        className="link-my-apps non-link-style"
        id="toggleUserOptions-link-myApps"
      >
        <SettingsIcon
          style={{ color: 'black', marginRight: 8, fontSize: 18 }}
          alt="enabled-settings-icon"
        />
        <span>Settings</span>
      </Link>
    </div>
  );

  const getDropdownOptions = () => (
    <div className="user-navigation-wrapper">
      <div>
        <Link
          to={myAppsUrl}
          className="link-my-apps non-link-style"
          id="toggleUserOptions-link-myApps"
        >
          <CreateIcon
            style={{ color: 'black', marginRight: 8, fontSize: 18 }}
            alt="enabled-add-icon"
          />
          {formattedMessage(messages.myApps)}
        </Link>
      </div>
      {userIsAdmin ? (
        <div>
          <Link
            to={inviteDevelopersUrl}
            className="link-invite-developer"
            id="toggleUserOptions-link-inviteDevelopers"
          >
            <SendIcon
              style={{ color: 'black', marginRight: 8, fontSize: 18 }}
              alt="enabled-add-icon"
            />
            {formattedMessage(messages.inviteDevelopers)}
          </Link>
        </div>
      ) : (
        ''
      )}
      <div>
        <a
          href="https://accounts.autodesk.com/Profile/EditProfile"
          target="_blank"
          className="link-edit-profile"
          id="toggleUserOptions-link-editProfile"
          rel="noreferrer"
        >
          <PersonIcon
            style={{ color: 'black', marginRight: 8, fontSize: 18 }}
            alt="enabled-person-icon"
          />
          {formattedMessage(messages.editProfile)}
        </a>
      </div>
      {displayWebhookSettings && selectedCompany && settingsLink()}
    </div>
  );

  const displayUserName = () => {
    if (userFirstName || userLastName) {
      return <span className="user-name">{userFirstName || userLastName}</span>;
    }

    return null;
  };

  const mainContainer = () => (
    <span
      onClick={() => setShow(!show)}
      className="toggle-container-wrapper blue-line-links"
      id="toggleUserOptions-toggle-arrow"
      ref={userNavRef}
    >
      {displayUserName()}
      <span className="toggle-user-options-wrapper">
        <div className="toggle-arrow" />
        {show ? getDropdownOptions() : ''}
      </span>
    </span>
  );

  return userLogin ? mainContainer() : null;
};

ToggleUserOptions.propTypes = {
  myAppsUrl: PropTypes.string,
  userLogin: PropTypes.bool,
  userIsAdmin: PropTypes.bool,
  userLastName: PropTypes.string,
  userFirstName: PropTypes.string,
  inviteDevelopersUrl: PropTypes.string,
  settingsUrl: PropTypes.string,
  selectedCompany: PropTypes.object,
  displayWebhookSettings: PropTypes.bool,
};

export default ToggleUserOptions;
